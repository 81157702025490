import React from "react"
import Navigation from "./NavigationContainer"
import { graphql, useStaticQuery } from "gatsby"

interface NavProps {
  toggleMenu(bool: boolean): void
  menuIsOpen: boolean
  slug: string
}

const NavigationDrupal = ({ toggleMenu, menuIsOpen, slug }: NavProps) => {
  const data: any = useStaticQuery(graphql`
    query navigation {
      nodeNavigation {
        enquiryHeading: field_dropdown_heading
        enquiryText: field_dropdown_body
        cta: field_cta {
          title
          uri
        }
        relationships {
          field_navigation_type {
            relationships {
              field_component_type {
                __typename
                ... on paragraph__dropdown_menu {
                  id
                  field_dropdown_heading
                  field_breadcrumb_link
                  field_dropdown_links {
                    title
                    uri
                  }
                }
                ... on paragraph__extended_dropdown {
                  id
                  field_dropdown_heading
                  field_breadcrumb_link
                  relationships {
                    field_link_column {
                      field_top_link {
                        title
                        uri
                      }
                      relationships {
                        field_inner_links {
                          ... on paragraph__dropdown_menu {
                            id
                            field_dropdown_links {
                              title
                              uri
                            }
                            field_dropdown_heading
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__navigation_link {
                  id
                  field_link {
                    title
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const nav = data.nodeNavigation.relationships.field_navigation_type
  const { enquiryHeading, enquiryText, cta } = data.nodeNavigation

  const links = nav.map(obj => {
    const data = obj.relationships.field_component_type
    const typename = data.__typename
    switch (typename) {
      case "paragraph__extended_dropdown":
        const superDropdown = data.relationships.field_link_column.map(col => {
          const linksObj = col.relationships.field_inner_links.map(iLinks => {
            return {
              subheading: iLinks.field_dropdown_heading,
              links: iLinks.field_dropdown_links,
            }
          })

          return {
            heading: col.field_top_link.title,
            headingUri: col.field_top_link.uri,
            links: linksObj,
          }
        })

        return {
          title: data.field_dropdown_heading,
          bLink: data.field_breadcrumb_link,
          superDropdown,
        }
      case "paragraph__navigation_link":
        return {
          title: data.field_link[0].title,
          uri: data.field_link[0].uri,
        }
      case "paragraph__dropdown_menu":
        return {
          title: data.field_dropdown_heading,
          bLink: data.field_breadcrumb_link,
          dropdown: data.field_dropdown_links,
        }
      default:
        return typename
    }
  })

  return (
    <Navigation
      menuIsOpen={menuIsOpen}
      toggleMenu={toggleMenu}
      enquiryHeading={enquiryHeading}
      enquiryText={enquiryText}
      cta={cta}
      links={links}
      slug={slug}
    />
  )
}

export default NavigationDrupal
