import Footer from "./Footer"
import React from "react"
import { formatLink, get } from "../../util/functions"
import { graphql, useStaticQuery } from "gatsby"

interface FooterInterface{
  mini?: boolean
}

const FooterDrupal:React.FC<FooterInterface> = ({mini}) => {
  const data: any = useStaticQuery(graphql`
    query footer {
      nodeFooter {
        whatsApp: field_whatsapp_link {
          uri
          title
        }
        followTitle: field_social_media_heading
        copyright: field_legal_text {
          value
        }
        bLinks: field_bottom_links {
          uri
          title
        }
        pLinks: field_practice_links {
          uri
          title
        }
        relationships {
          linkBlocks: field_link_block {
            field_link {
              uri
              title
            }
          }
          sm: field_social_icon {
            field_icon {
              alt
            }
            field_icon_hovered {
              alt
            }
            relationships {
              field_icon {
                localFile {
                  publicURL
                }
              }
              field_icon_hovered {
                localFile {
                  publicURL
                }
              }
            }
            field_link {
              uri
            }
          }
        }
      }
    }
  `)
  const { followTitle, relationships, copyright, pLinks, bLinks, whatsApp } =
    data.nodeFooter || {}
  const { sm, linkBlocks } = relationships

  const socialMedia = sm.map((media: any) => {
    const uri = media.field_link[0].uri
    const icon = get(
      ["relationships", "field_icon", "localFile", "publicURL"],
      media,
      ""
    )
    const iconHovered = get(
      ["relationships", "field_icon_hovered", "localFile", "publicURL"],
      media,
      ""
    )

    return {
      uri,
      icon,
      iconHovered,
    }
  })

  const socialIconAlt = sm.map((media: any) => {
    const alt = media.field_icon.alt

    return {
      alt,
    }
  })
  const bottomLinks = bLinks.map((link: any) => {
    return {
      title: link.title,
      url: formatLink(link.uri),
    }
  })

  const locationLinks = pLinks.map((link: any) => {
    return {
      title: link.title,
      url: formatLink(link.uri),
    }
  })

  const links = linkBlocks.map((section: any) => {
    const block = section.field_link.map((links: any) => {
      return {
        title: links.title,
        url: formatLink(links.uri),
      }
    })
    return block
  })

  return (
    <Footer
      followTitle={followTitle}
      socialMedia={socialMedia}
      socialIconAlt={socialIconAlt}
      links={links}
      bottomLinks={bottomLinks}
      copyright={copyright.value}
      locationLinks={locationLinks}
      whatsApp={whatsApp}
      mini={mini}
    />
  )
}

export default FooterDrupal
