import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link, Image, RightCol, AppBar, Toolbar } from "./MobileNavigation"
import logo from "../../white-co-images/whiteandco-logo.svg"
import color from "../../style/color"
import { formatLink } from "../../util/functions"
import Button from "../Button/Button"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import BurgerMenuLink from "./BurgerMenuLink"
import arrow from "../../white-co-images/burger-arrow.svg"

interface BurgerMenuProps {
  links: any
  closeMenu: any
  scrollValue: number
  enquiryHeading: string
  enquiryText: string
  cta: any
}

const BurgerMenu = ({
  links,
  closeMenu,
  scrollValue,
  enquiryHeading,
  enquiryText,
  cta,
}: BurgerMenuProps) => {
  useEffect(() => {
    const scrollable = document.getElementById("menu-mob-scrollable")
    const body = document.querySelector("body")

    disableBodyScroll(scrollable)

    return () => {
      enableBodyScroll(body)
      clearAllBodyScrollLocks()
      document.documentElement.scrollTop = document.body.scrollTop = scrollValue
    }
  }, [])

  const [currentLinks, setCurrentLinks] = useState(links)
  const [backFunction, setBackFunction] = useState(null)
  const [heading, setHeading] = useState(null)
  const [headingLink, setHeadingLink] = useState(null)

  const resetLinks = () => {
    setCurrentLinks(links)
    setBackFunction(null)
    setHeading(null)
  }

  const setDropdownLinks = (
    newLinks,
    superDropdown,
    heading,
    headingLink = null
  ) => {
    setCurrentLinks(newLinks)
    setHeading(heading)
    setBackFunction(() => resetLinks)
  }

  return (
    <Container>
      <AppBar>
        <Toolbar transparent>
          <Link href={process.env.BASE}>
            <Image src={logo} alt={"White and Co logo"} />
          </Link>
          <RightCol>
            <Menu onClick={closeMenu}>
              <Line first />
              <Line second />
              <Line third />
            </Menu>
          </RightCol>
        </Toolbar>
      </AppBar>
      <LinksContainer id="menu-mob-scrollable">
        <LinkContainer>
          {backFunction !== null && (
            <BackLink onClick={backFunction}>
              <Arrow src={arrow} /> Back
            </BackLink>
          )}
          {heading && (
            <LinkHeading isLink={headingLink} href={headingLink}>
              {heading}
            </LinkHeading>
          )}
          {currentLinks.map((item, key) => {
            return (
              <BurgerMenuLink
                setDropdownLinks={setDropdownLinks}
                setBackFunction={setBackFunction}
                link={
                  !item.superDropdown && !item.dropdown
                    ? formatLink(item.uri)
                    : null
                }
                key={key}
                text={item.title || item.heading}
                dropdown={item.dropdown}
                superDropdown={item.superDropdown}
                linkOnClick={
                  item.links &&
                  (e => {
                    e.preventDefault()
                    if (item.links) {
                      const newLinks = []
                      for (let i = 0; i < item.links.length; i++) {
                        for (let j = 0; j < item.links[i].links.length; j++) {
                          newLinks.push(item.links[i].links[j])
                        }
                      }
                      setHeading(item.heading)
                      setHeadingLink(formatLink(item.headingUri))
                      setCurrentLinks(newLinks)
                      setBackFunction(() => () => {
                        setCurrentLinks(currentLinks)
                        setBackFunction(() => resetLinks)
                        setHeading(heading)
                        setHeadingLink(null)
                      })
                    }
                  })
                }
              />
            )
          })}
        </LinkContainer>

        <Enquiry>
          <h6>{enquiryHeading}</h6>
          <p>{enquiryText}</p>
          {cta && <Button text={cta.title} href={cta.uri} />}
        </Enquiry>
      </LinksContainer>
    </Container>
  )
}

export default BurgerMenu

const BackLink = styled.div`
  padding: 20px 25px;
  background: #001020;
  color: white;
  cursor: pointer;
  border-top: 1.25px solid ${color.keyline.green};
  border-bottom: 1.25px solid ${color.keyline.green};
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`
interface ILinkHeading {
  isLink?: boolean
}

const LinkHeading = styled.a<ILinkHeading>`
  font-size: 18px;
  line-height: 28px;
  padding: 10px 25px;
  background: ${color.brand.blue};
  color: ${color.background.lightGrey};
  font-weight: bold;
  border-bottom: 1.25px solid ${color.keyline.green};

  &:link,
  &:visited {
    color: ${color.background.lightGrey};
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    font-weight: bold;
  }

  &:hover,
  &:active {
    color: ${color.background.lightGrey};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
interface ILine {
  first?: boolean
  second?: boolean
  third?: boolean
  menuIsOpen?: boolean
}
const Line = styled.div<ILine>`
  width: 27px;
  height: 2px;
  margin: ${props => props.second && "5px 0"};
  background-color: #000;
  width: ${props => props.menuIsOpen && "15px"};

  transform-style: preserve-3d;
  transition: all 200ms ease 0s;
  opacity: 0;
  will-change: transform;
  backface-visibility: hidden;
`
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 5;
`
const Enquiry = styled.div`
  flex: 1;
  padding: 18px 25px 73px;
  background: ${color.background.blueCookie};

  font-size: 15px;
  line-height: 25px;

  & h6 {
    font-weight: 500;
    margin-bottom: 6px;
  }

  & p {
    font-size: 13px;
    line-height: 23px;
    margin-bottom: 8px;
  }
`

const Arrow = styled.img`
  height: 12px;
  transform: rotate(90deg);
  margin-right: 5px;
`
