import React, { useState, useEffect } from "react"
import logo from "../../white-co-images/whiteandco-logo.svg"
import BurgerMenu from "./BurgerMenu"
import color from "../../style/color"
import { Link as Lnk } from "gatsby"

import styled from "styled-components"

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

interface NavigationProps {
  links: Array<any>
  enquiryHeading?: string
  enquiryText?: string
  menuIsOpen: boolean
  toggleMenu: any
  cta: any
}

const MobileNavigation = ({
  links,
  enquiryHeading,
  enquiryText,
  cta,
  menuIsOpen,
  toggleMenu,
}: NavigationProps) => {
  const [scrollValue, setScrollValue] = useState(0)

  const handleOpenBurgerMenu = () => {
    setScrollValue(window.pageYOffset)
    toggleMenu(!menuIsOpen)
  }
  useEffect(() => {
    const getWindowScroll = () => {
      const container = document.getElementById("mobile-nav-container")
      const nav = document.getElementById("mobile-container-nav")

      if (container && nav) {
        if (window.pageYOffset > 350) {
          container.style.marginBottom = "73px"

          nav.style.cssText =
            "position: fixed; top: 0; left: 0; right: 0; z-index: 999;"
        } else if (window.pageYOffset <= 350) {
          nav.style.position = "relative"
          container.style.marginBottom = "0px"
        }
      }
      window.addEventListener("scroll", getWindowScroll)
    }
    return () => {
      window.removeEventListener("scroll", getWindowScroll)
    }
  }, [])
  useEffect(() => {
    let last: number = 0
    const animateNavigation = () => {
      const nav: HTMLElement | null = document.getElementById(
        "mobile-container-nav"
      )
      const container: HTMLElement | null = document.getElementById(
        "mobile-nav-container"
      )
      const topNav: HTMLElement | null = document.getElementById("top-nav")
      const menu: HTMLElement | null = document.getElementById("menuBtn")

      if (typeof window !== "undefined" && typeof document !== "undefined") {
        if (nav && container && topNav && menu) {
          let currentScroll: number =
            document.documentElement.scrollTop || document.body.scrollTop

          // initial scroll down -> nav sticky
          if (
            currentScroll >= 0 &&
            currentScroll <= 150 &&
            window.innerWidth <= 1023
          ) {
            last = currentScroll
            container.style.display = "block"
            nav.style.display = "none"
            topNav.style.visibility = "initial"
          }
          // scroll down more than 67 -> nav disappear
          else if (
            currentScroll > 0 &&
            last <= currentScroll &&
            window.pageYOffset > 0 &&
            !menuIsOpen
          ) {
            last = currentScroll
            nav.style.display = "none"
            topNav.style.visibility = "initial"
          }

          // scroll up -> nav appear
          else if (currentScroll > 0 && window.innerWidth <= 1023) {
            nav.style.display = "block"
            topNav.style.visibility = "hidden"
            last = currentScroll
          }
        }
      }
    }
    window.addEventListener("scroll", animateNavigation)

    return () => {
      window.removeEventListener("scroll", animateNavigation)
    }
  }, [])

  useEffect(() => {
    const resetBody = () => {
      const body: HTMLElement | null = document.querySelector("body")
      if (window && body) {
        if (window.innerWidth > 1023) {
          enableBodyScroll(body)
          clearAllBodyScrollLocks()
          toggleMenu(false)
        } else if (menuIsOpen) {
          // disableBodyScroll(scrollable)
        }
      }
    }

    window.addEventListener("resize", resetBody)

    return () => {
      window.removeEventListener("resize", resetBody)
    }
  }, [])

  return (
    <Outer id="mobile-nav-container">
      {menuIsOpen ? (
        <BurgerMenu
          closeMenu={() => {
            toggleMenu(!menuIsOpen)
          }}
          links={links}
          scrollValue={scrollValue}
          enquiryHeading={enquiryHeading}
          enquiryText={enquiryText}
          cta={cta}
        />
      ) : (
        <></>
      )}
      <Container id="top-nav">
        <AppBar menuOpen={menuIsOpen}>
          <Toolbar>
            {!menuIsOpen && (
              <Link to={process.env.BASE}>
                <Image src={logo} alt={"White & Co logo"} />
              </Link>
            )}
            <RightCol>
              <Menu onClick={handleOpenBurgerMenu} id="menuBtn">
                <Line first menuIsOpen={menuIsOpen} />
                <Line second menuIsOpen={menuIsOpen} />
                <Line third menuIsOpen={menuIsOpen} />
              </Menu>
            </RightCol>
          </Toolbar>
        </AppBar>
      </Container>
      <Container id="mobile-container-nav" hidden>
        <AppBar menuOpen={menuIsOpen}>
          <Toolbar>
            <Link to={process.env.BASE}>
              <Image src={logo} alt={"White and Co logo"} />
            </Link>
            <RightCol>
              <Menu onClick={handleOpenBurgerMenu}>
                <Line first menuIsOpen={menuIsOpen} />
                <Line second menuIsOpen={menuIsOpen} />
                <Line third menuIsOpen={menuIsOpen} />
              </Menu>
            </RightCol>
          </Toolbar>
        </AppBar>
      </Container>
    </Outer>
  )
}

const Outer = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`
const Container = styled.div`
  flex-grow: 1;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  @media (max-width: 1023px) {
    ${props =>
      props.hidden ? `position: fixed; top: 0;z-index:999;` : `display: block;`}
  }
`

export const Link = styled(Lnk)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }
`

export const Image = styled.img`
  width: auto;
  height: 17px;
  cursor: pointer;
`

export const RightCol = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Phone = styled(Image)`
  width: 35px;
  height: 29px;
  margin-right: 30px;
  cursor: pointer;
`

export const Menu = styled.div`
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 5;
`

interface ILine {
  first?: boolean
  second?: boolean
  third?: boolean
  menuIsOpen?: boolean
}
export const Line = styled.div<ILine>`
  width: 27px;
  height: 2px;
  margin: ${props => props.second && "6px 0"};
  background-color: ${color.brand.green};
  transform-style: preserve-3d;
  transition: all 200ms ease 0s;
  z-index: 7;
  will-change: transform;
  backface-visibility: hidden;
  ${props =>
    props.second &&
    props.menuIsOpen &&
    "transition: opacity 200ms ease 0s width 200ms ease 0s; opacity: 0; width: 0px;"}
  transform: ${props =>
    props.first && props.menuIsOpen
      ? "translateX(0px) translateY(8px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(45deg)"
      : props.first
      ? "translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)"
      : props.third && props.menuIsOpen
      ? "translateX(0px) translateY(-8px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(-45deg)"
      : "translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)"};
`

interface IAppBar {
  menuOpen?: boolean
}
export const AppBar = styled.div<IAppBar>`
  box-shadow: none;
  z-index: 5;
  position: relative;
  ${props => props.menuOpen && "position: fixed; top: 0; left: 0; right: 0;"}
`

interface IToolbar {
  transparent?: boolean
}
export const Toolbar = styled.div<IToolbar>`
  background-color: ${props =>
    props.transparent ? "transparent" : color.brand.blue};
  padding: 15px 25px;
  min-height: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export default MobileNavigation
